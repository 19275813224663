.container {
    display:flex;
    justify-content:center;
    align-items:center;
    height:150px;
    z-index:0;
  }
  @keyframes spin {
    0% {transform:rotate(0);}
    50% {transform:rotate(360deg) scale(.9)}
    100% {transform:rotate(720deg);}
  }
  .spinner {
     animation:spin 1s infinite linear;
    display:flex;
    justify-content:center;
    align-items:center;
    height:50px;
    width:50px;
    background-color:#96afdb;
    border-radius:50px;
    overflow:hidden;
    margin:0 auto;
    
  }
  .spinner::before{
    content:'';
    display:block;
    background-color:#0066b2;
    box-shadow:0px 0px 20px #0066b2;
    height:15px;
    width:15px;
    border-radius:15px;
    margin-left:20px;
    position: absolute;
    z-index:1;
  }
  .spinner::after {
    content:'';
    height:40px;
    width:40px;
    background-color:white;
    border-radius:40px;
    z-index:2;
  }
  .small {
    font-size:.7em;
  }
  .ant-form-item-label {
    font-variant-caps: all-small-caps
  }
  .tag{
    border: solid 1px #999;
    padding: 3px;
    margin: 3px;
    background-color: #eee;
    user-select: none;
    pointer-events: none;
  }
  #addBoxForm {
    position: absolute;
    background-color: #fff;
    border: solid 1px #ddd;
    padding: 8px;
    text-align:center;
    z-index: 999;
    min-width:480px;
  }
  #deleteBox {
    position: absolute;
    background-color: #fff;
    border: solid 1px #ddd;
    padding: 8px;
    text-align:center;
    z-index: 999;
    min-width:200px;
    display:flex;

  }
  .ant-modal-body{
    padding:0!important;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 0px!important; 
  }
  .ant-carousel .slick-dots li.slick-active button {
    background-color: #0066b2!important;
}
.ant-carousel .slick-dots li {
  background: cadetblue;
}
.card-spinner {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  padding: 5px;
}
.card-alert {
  position: absolute;
  z-index:1;
  top: 50px;
  left: 0;
  margin: 6px 0px;
  width: 100%;
}

.sticky-header {
  background-color:#fff;
  min-height:100px;
}